import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Chama a gente pra conversar 😍😆`}</h2>
    <p>{`Envie-nos um `}<a parentName="p" {...{
        "href": "mailto:contato@acstecnologiaeservicos.com.br"
      }}>{`E-mail`}</a>{` clicando `}<a parentName="p" {...{
        "href": "mailto:contato@acstecnologiaeservicos.com.br"
      }}>{`Aqui`}</a>{` ou pode nos chamar nas seguintes plataformas: `}<a parentName="p" {...{
        "href": "https://www.instagram.com/acstecnologiaeservicos/"
      }}>{`Instagram`}</a>{` & `}<a parentName="p" {...{
        "href": "https://api.whatsapp.com/send?phone=5564999354485&text=Obrigado%20por%20entrar%20em%20contato%20conosco."
      }}>{`Whatsapp`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      