import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Sobre Nós`}</h2>
    <blockquote>
      <p parentName="blockquote">{`Somos uma fábrica de soluções digitais fazendo pontes entre negócio e tecnologia. Coloque seu negócio em um próximo nível, e deixe a gente te ajudar!`}</p>
    </blockquote>
    <p>{`Somos uma empresa com profissionais multidisciplinares, capacitados e focados no entendimento do negócio de cada cliente para construção de soluções para qualquer tipo de segmento de mercado. Trabalhamos com uma metodologia ágil, que veio para resolver, na prática, o desenvolvimento de sistemas e projetos complexos, sem desperdício e no prazo desejado.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      