import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Nossos Diferenciais`}</h2>
    <ProjectCard title="Agilidade no Desenvolvimento" link="#" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
  Sabemos a importância de acertar o timing de um projeto de software, por isso
  trabalhamos com metodologias ágeis.
    </ProjectCard>
    <ProjectCard title="Experiência e Conhecimento" link="#" bg="linear-gradient(to right, #662D8C 0%, #ED1E79 100%)" mdxType="ProjectCard">
  Trabalhamos com diversos segmentos e temos o conhecimento necessário para
  atender os mais variados projetos.
    </ProjectCard>
    <ProjectCard title="Flexibilidade" link="#" bg="linear-gradient(to right, #009245 0%, #FCEE21 100%)" mdxType="ProjectCard">
  Capacidade de prover recursos para seu software, em qualquer etapa do ciclo de
  vida de desenvolvimento.
    </ProjectCard>
    <ProjectCard title="Sustentação e Evolução Contínua" link="#" bg="linear-gradient(to right, #D585FF 0%, #00FFEE 100%)" mdxType="ProjectCard">
  Sistemas construidos com dinamismo e possibilidade de evolução contínua.
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      