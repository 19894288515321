import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Olá, Somos a ACS Tecnologia e Serviços`}</h1>
    <p>{`Somos uma Fábrica de Software com foco em criar soluções únicas e moldadas à necessidade de cada cliente. Possuímos skills que nos tornam capazes de iniciar projetos do zero, desde a concepção do produto até a solução final e completa de software full-stack.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      